import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ToastComponent } from './services/toast/components/toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from './interceptors/http-client.interceptor';
import { FormsModule } from '@angular/forms';
import { MatTableSortComponent } from './mat-table-sort/mat-table-sort.component';
import {MatSortModule} from '@angular/material/sort';
import { AttendanceMonthlyRoutingModule } from './main/components/attendance-monthly/attendance-monthly-routing.module';


@NgModule({
  declarations: [
    AppComponent,
    MatTableSortComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSortModule,
    AttendanceMonthlyRoutingModule

  ],

  providers:[
    ToastComponent,
    MatSnackBar,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
  ],


  bootstrap: [AppComponent]
})
export class AppModule { }
