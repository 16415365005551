import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendanceMonthlyComponent } from './attendance-monthly/attendance-monthly.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
  path:'',component:AttendanceMonthlyComponent
}];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ]
})
export class AttendanceMonthlyRoutingModule { }
