import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { PdfService } from 'src/app/services/pdfDownload/pdfService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { FileUpload } from 'src/app/shared/classes/file-upload';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';
import { DatePipe, Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CsvService } from 'src/app/attendance/service/csv.service';
import { ErpService } from 'src/app/services/erp.service';
import { EmplyoeeService } from 'src/app/attendance/service/emplyoee.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

interface MonthObject {
  name: string;
  value: number;
}

@Component({
  selector: 'app-attendance-monthly',
  templateUrl: './attendance-monthly.component.html',
  styleUrls: ['./attendance-monthly.component.scss']
})
export class AttendanceMonthlyComponent {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'date',
    'inTime',
    'outTime',
    'lunchInTime',
    'lunchOutTime',
    'breakInTime',
    'breakOutTime',
    'TotalHour'
  ];
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  // @ViewChild(MatSort)
  // sort!: MatSort;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  userMenuList: any;
  moment: any = moment;
  searchfilter: any;
  listDate: any;
  startdate: any;
  enddate: any;
  userData: any = localStorage.getItem('userInfo');
  userInfo: any;
  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatSort, {static: true}) sort!: MatSort;

  months: MonthObject[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
];
  empDetails: any;
  selectedMonthId: any;
  selectedEmpId: any;

  constructor(
    private ErpService: ErpService,
    private datePipe: DatePipe,
    private csvService: CsvService,
    private employeeService: EmplyoeeService,
  ) {
    this.userInfo = JSON.parse(this.userData);
    this.getAllOfficeEmployeeDetails();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllOfficeEmployeeDetails(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.employeeService.getAllOfficeEmployeeDetails().subscribe((data: any) => {
        this.empDetails = data;
      },
        (error: any) => {
          reject("API Error: " + error.message);
        });
    });
  }

  downloadCSV() {
    const csvData = this.convertToCSV(this.dataSource.data);
    this.csvService.download(csvData, 'attendance.csv'); // Assume a method named 'download' in CsvService to initiate the download
  }

  convertToCSV(data: any[]): string {
    const header = ['image','Employee Id', 'Employee Name', 'Status', 'Date', 'Time']; // Add more headers if needed
    const csvRows = [];

    // Push header
    csvRows.push(header.join(','));

    // Push data rows
    data.forEach((row: any) => {
      const values = [
        row.image,
        row.empID,
        row.empName,
        row.status,
        this.formatDate(row.date), // Assuming you have a method to format date
        this.formatTime(row.date), // Assuming you have a method to format time
      ];
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  }

  formatDate(date: string): string {
    return date; // For now, just returning the date as it is
  }

  formatTime(time: string): string {
    return time; // For now, just returning the time as it is
  }

  selectedMonth(event: Event): void {
    this.selectedMonthId = (event.target as HTMLSelectElement).value;
}

selectedEmp(event: Event){
  this.selectedEmpId = (event.target as HTMLSelectElement).value;
}

fetchData(){
  this.employeeService.fetchEmployeeDetails(this.selectedEmpId, this.selectedMonthId).subscribe(
    (response: any) => {
      console.log(response,'iyf')
      this.dataSource.data = response.entries;
      console.log(this.dataSource.data,'iuytr')
    },
    (error: any) => {
      this.ErpService.toast.snackbarError("API Error: " + error.message);
    }
  );
}


}
