<div class="d-flex align-items-center justify-content-between pt-4 px-4">
    <h4 class="request-heading ">Attendance Monthly</h4>
   
    
     <!-- <button mat-raised-button color="primary" (click)="downloadCSV()" class="btn-grad">
      Download CSV
    </button>  -->
  </div>
  <div class="main-box shadow p-4 ">
    <div class="index-2">
      <div class="d-flex align-items-start gap-3">
        <div>
          <select  (change)="selectedMonth($event)">
              <option value="" disabled selected>Select a month</option>
              <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
          </select>
      </div>

      <div>
        <select (change)="selectedEmp($event)">
            <option value="" disabled selected>Select a Employee</option>
            <option *ngFor="let emp of empDetails" [value]="emp.officialId">{{ emp.username }} {{ emp.officialId }}</option>
        </select>
    </div>

    <button (click)="fetchData()">Search</button>
      </div>
     
      <div>
        <div class="mat-table-container">
          <table mat-table [dataSource]="dataSource" matSort class="mat-table mat-elevation-z8">
            <!-- Image Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef class="blue-theme">
                Date
              </th>
                <td mat-cell *matCellDef="let element">{{ element?.date }}</td>
            </ng-container>
        
            <!-- Employee ID Column -->
            <!-- <ng-container matColumnDef="VID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">
                VID
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.empID }}</td>
            </ng-container> -->
        
            <!-- Employee Name Column -->
            <!-- <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">
                Name
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.empName }}</td>
            </ng-container> -->
        
            <!-- Date Column -->
            <ng-container matColumnDef="inTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">In</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.inTime }}
                <!-- {{ element?.inTime | date : "dd-MM-yyyy" }} -->
              </td>
            </ng-container>
        
            <!-- Time Column -->
            <ng-container matColumnDef="outTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Out</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.outTime ? element.outTime :"-"}}
                <!-- {{ element?.outTime | date : "hh:mm a" }} -->
              </td>
            </ng-container>
        
            <!-- Status Column -->
            <ng-container matColumnDef="lunchInTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Lunch In</th>
              <td mat-cell *matCellDef="let element">{{ element.lunchInTime ? element.lunchInTime : "-" }}</td>
            </ng-container>
        

            <ng-container matColumnDef="lunchOutTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Lunch Out</th>
                <td mat-cell *matCellDef="let element">{{ element.lunchOutTime ? element.lunchOutTime :"-" }}</td>
              </ng-container>

              <ng-container matColumnDef="breakInTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Break In</th>
                <td mat-cell *matCellDef="let element">{{ element.breakInTime ? element.breakInTime :"-"}}</td>
              </ng-container>

              <ng-container matColumnDef="breakOutTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Break Out</th>
                <td mat-cell *matCellDef="let element">{{ element.breakOutTime ? element.breakOutTime :"-" }}</td>
              </ng-container>

              <ng-container matColumnDef="TotalHour">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="blue-theme">Total Hour</th>
                <td mat-cell *matCellDef="let element">{{ element.TotalHour ? element.TotalHour :0 }}</td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="blue-theme"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator #paginator [pageSize]="pageSize" [pageIndex]="pageIndex" class="calling-list-pagenation blue-theme" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons aria-label="Select page"></mat-paginator>
        </div>
      
      </div>
    </div>
  </div>
  