import { Component } from '@angular/core';

@Component({
  selector: 'app-mat-table-sort',
  templateUrl: './mat-table-sort.component.html',
  styleUrls: ['./mat-table-sort.component.scss']
})
export class MatTableSortComponent {

}
