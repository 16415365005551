import { Injectable } from '@angular/core';
import { ApiService } from '../../../app/services/service';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {
  // localpath:any = `http://localhost:4000`;
  localpath:any=`https://erp.ithemes.xyz`
  // localpath1:any=`http://localhost:8088/api`
  localpath1:any = 'https://erp.api.attendance.stageprojects.xyz/api'


  signUpUser(data:any) {
    const { body} =  data;
    return this.request({
      path:`${this.localpath}/signup`,
      method:"POST",
      body
    });
  }
  logInUser(data:any) {
    const { body} =  data;
    return this.request({
      path:`${this.localpath1}/login`,
      method:"POST",
      body
    });
  }
}
