import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// https://erp.api.attendance.stageprojects.xyz/api/
@Injectable({
  providedIn: 'root',
})
export class EmplyoeeService {
  //  ApiPath:any = 'http://localhost:8088/api/'
  // ApiPath: any = 'http://localhost:8088/api/getemployee';
  // localpath:any=`https://erp.ithemes.xyz`
  ApiPath:any = 'https://erp.api.attendance.stageprojects.xyz/api/'


  employeeAttendance = new BehaviorSubject('');
  disableEnterBtn = new Subject();
  employeePicClick = new Subject();
  employeePic = new Subject();
  private isLoggedIn: boolean = false; // Initialize isLoggedIn as false
  private token: string | null = null; 
  constructor(private http: HttpClient) {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    this.setToken(storedLoggedIn);
  }

  setToken(token: any) {
    this.token = token;
    this.isLoggedIn = true; // Set isLoggedIn to true after setting token
  }

  clearToken() {
    this.token = null;
    this.isLoggedIn = false;
  }

  private getHeaders() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (this.isLoggedIn && this.token) {
      const tokenWithoutQuotes = this.token.replace(/^"(.*)"$/, '$1');
      headers = headers.set('Authorization', `Bearer ${tokenWithoutQuotes}`);
    }
    
    return headers;
  }

  disableEnterBtnFun(value: any) {
    this.disableEnterBtn.next(value);
  }

  employeeAttendanceFun(value: any) {
    this.employeeAttendance.next(value);
  }

  employeePicClickFun(value: any) {
    this.employeePicClick.next(value);
  }

  employeePicFun(value: any) {
    this.employeePic.next(value);
  }

  getEmployeeDetailsById(employeeId: any): Observable<any> {
    return this.http.get(`${this.ApiPath}findByEmployeeID/${employeeId}`, {
      headers: this.getHeaders(),
    });
  }

  findEmployeeDetailByFilterByName(empName?: string, fromDate?: string, toDate?: string): Observable<any> {
    let queryParams = `empName=${empName}`;
    if (fromDate) {
      queryParams += `&fromdate=${fromDate}`;
    }
    
    if (toDate) {
      queryParams += `&todate=${toDate}`;
    }
    const url = `${this.ApiPath}/findEmployeeDetailByFilter?${queryParams}`;
    return this.http.get(url, {
      headers: this.getHeaders()
    });
  }
  

  getEmployeeDetailsByIdToday(employeeId: any): Observable<any> {
    return this.http.get(`${this.ApiPath}findEmployeeToday/${employeeId}`, {
      headers: this.getHeaders(),
    });
  }

  findEmployeeDetailByFilter(): Observable<any> {
    return this.http.get(`${this.ApiPath}findEmployeeDetailByFilter`, {
      headers: this.getHeaders(),
    });
  }

  getAllEmployeeDetailsToday(): Observable<any> {
    return this.http.get(`${this.ApiPath}findallemployeeDetailToday`, {
      headers: this.getHeaders(),
    });
  }

  getAllOfficeEmployeeDetails(): Observable<any> {
    return this.http.get(`${this.ApiPath}getOfficeAllEmployees`, {
      headers: this.getHeaders(),
    });
  }

  addEmployeeAttendance(data: any): Observable<any> {
    return this.http.post<any>(`${this.ApiPath}addEmployeeAttendance`, data, {
      headers: this.getHeaders(),
    });
  }

  fetchEmployeeDetails(empId: any, month: any): Observable<any> {
    return this.http.get<any>(`${this.ApiPath}findallemployeeDetailById?empID=${empId}&month=${month}`, {
      headers: this.getHeaders(),
    });
  }

  findEmployeeForSevenDays() {
    return this.http.get<any>(`${this.ApiPath}findEmployeeForSevenDays`, {
      headers: this.getHeaders(),
    });
  }
}